<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  mounted() {
  }
}
</script>

<style lang="less">
*::-webkit-scrollbar {
  display: none;
}
</style>
