import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    code: '',
    fission_id: 0,
    union_id: ''
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
